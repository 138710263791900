.banner-section {
    height: auto;
    background-image: linear-gradient(to top right, #2a3572, #4354B5);
    position: relative;
    padding-top: 130px;
    overflow: hidden;
}
.banner-section:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 100vh;
    background-image: url(./../images/banner/banner-bottom-shape.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 100%;
}
.banner-section .banner-content {
    text-align: center;
    padding-bottom: 150px;
}

.banner-text h1 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 15px;
    line-height: 1.2;
}
.banner-text p {
    color: #fff;
}
.banner-joint-image {
    position: relative;
}

.banner-joint-image .img1 {
    max-width: 100%;
    box-shadow: 0 10px 30px 0 rgba(13, 0, 46, 0.1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.banner-joint-image .img2 {
    position: absolute;
    left: 0px;
    top: -170px;
    box-shadow: 0 5px 15px 0 rgba(13, 0, 46, 0.1);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 30px;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
}


/* banner button */
.banner-text .call-toaction {
    padding-top: 30px;
}
.banner-text .call-to-action .custom-btn {
    margin-right: 20px;
    margin-bottom: 10px;
}

.banner-text .call-to-action .custom-btn:hover {
    background-color: white;
    color:#2A3572;
}
.banner-text .call-to-action .custom-btn:hover svg {
    fill: #2A3572;
}
.banner-text .call-to-action .custom-btn:hover p{
    color: #2A3572;
}
.banner-text .call-to-action .custom-btn:hover p span{
    color: #2A3572;
}






@media (min-width: 576px){
    .banner-text h1 {
        font-size: 36px;
        line-height: 1.2;
    }
    .banner-joint-image .img2 {
        -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
        left: 0px;
        top: -150px;
    }
}


@media (min-width: 768px){
    .banner-section {
        padding-top: 165px;
    }
}


@media (min-width: 992px){
    .banner-section {
        height: 100vh;
        padding-top: 0;
    }
    .banner-section .banner-content {
        height: 100%;
        text-align: left;
        padding-bottom: 0;
    }
    .banner-image-content {
        position: absolute;
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        padding-left: 100px;
        padding-top: 100px;
    }
    .banner-joint-image .img2 {
        -webkit-transform: scale(0.6);
        -ms-transform: scale(0.6);
        transform: scale(0.6);
        top: -150px;
        left: 10px;
    }
}

@media (min-width: 1200px){
    .banner-joint-image .img2 {
        left: 50px;
        top: -100px;
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@media (min-width: 1500px){
    .banner-text h1 {
        font-size: 42px;
        line-height: 56px;
    }
    .banner-image-content {
        padding-top: 50px;
    }
    .banner-joint-image .img2 {
        top: -100px;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}